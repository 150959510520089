<template>
    <v-container style="background-color: #00acc1" class="ma-0 pa-0">
        <v-row column>
            <v-col style="background-color: greenyellow;" class="col-4">
                <div>Payment Details</div>
                <div>Name: {{ directDepositDetails.accountName }}</div>
                <div>Bank: {{ directDepositDetails.bankName }}</div>
                <div>BSB: {{ directDepositDetails.bsb }}</div>
                <div>Account: {{ directDepositDetails.accountNumber }}</div>
            </v-col>
            <v-col style="background-color: greenyellow;" class="col-6">
                <div>{{ invoiceFooter.line1 }}&nbsp;</div>
                <div>{{ invoiceFooter.line2 }}&nbsp;</div>
                <div>{{ invoiceFooter.line3 }}&nbsp;</div>
                <div>{{ invoiceFooter.line4 }}&nbsp;</div>
                <div>{{ invoiceFooter.line5 }}&nbsp;</div>
            </v-col>
            <v-spacer style="background-color: orangered;" />
            <v-col align-center class="col-2 text-right" style="background-color: yellow;">
                <div style="background-color: greenyellow;">
                    <div v-if="pageNumber === totalPages" class="mr-1">
                        <div class="mr-4" style="font-weight: bold;">{{ totalSection.label }}</div>
                        <currency :value="totalSection.totalInc" />
                    </div>
                    <div v-else>Continued on page {{ pageNumber + 1 }}</div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Currency from '@/components/format/Currency';

export default {
    props: {
        footer: Object,
        pageNumber: Number,
        totalPages: Number,
    },
    components: { Currency },
    data() {
        return {
            directDepositDetails: null,
            invoiceFooter: null,
            totalSection: null,
        };
    },
    methods: {
        initialiseFields() {
            // const { footer, footer, body } = this.reportData;
            // if (!footer || !footer || !body) throw new Error(`Invoice bad format`);
            //
            this.directDepositDetails = this.footer.directDepositDetails;
            this.invoiceFooter = this.footer.invoiceFooter;
            this.totalSection = this.footer.totalSection;
        },
    },
    created() {
        this.initialiseFields();
    },
};
</script>
<style scoped>
.address-block {
    margin: 1cm 0cm 1cm 3cm;
}
</style>
