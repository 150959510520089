<template>
    <div v-if="this.pages">
        <report-page
            v-for="(page, pageCounter) in pages"
            landscape
            :key="`invoicePage${pageCounter}`"
        >
            <invoice-header
                :header="reportData.header"
                :pageNumber="pageCounter + 1"
                :totalPages="pages.length"
                slot="header"
            />
            <invoice-footer
                :footer="reportData.footer"
                :pageNumber="pageCounter + 1"
                :totalPages="pages.length"
                slot="footer"
            />
            <!--            <pre>{{ JSON.stringify(page, null, 2) }}</pre>-->
            <div>
                <!--                <v-container class="report-body pa-0 ma-0">-->
                <v-row
                    class="ma-0 pa-0 col-12 report-body"
                    style="background-color: #00bfa5; font-weight: bold;"
                >
                    <v-col class="col-2 ma-0 pa-1">Item</v-col>
                    <v-col class="col-6 ma-0 pa-1">Details</v-col>
                    <v-col class="col-2 ma-0 px-7 py-1 text-right">Ex Tax</v-col>
                    <v-col class="col-2 ma-0 px-7 py-1 text-right">Amount</v-col>
                </v-row>
                <v-row
                    v-for="(line, lineCount) in page.lines"
                    :key="`page${pageCounter}line${lineCount}`"
                    class="ma-0 pa-0"
                    style="font-weight: 350;"
                >
                    <v-col class="col-2 ma-0 pa-1">{{ line.itemName }}</v-col>
                    <v-col class="col-6 ma-0 pa-1">
                        {{ line.description }}
                    </v-col>
                    <v-col class="col-2 ma-0 pa-1 text-right" style="background-color: #00c853">
                        <currency :value="line.extaxAmount" />
                    </v-col>
                    <v-col class="col-2 ma-0 pa-1 text-right">
                        <currency :value="line.amount" />
                    </v-col>
                </v-row>
                <!--                </v-container>-->
            </div>
        </report-page>
        <div id="reportLoaded" />
    </div>
</template>
<script>
import InvoiceHeader from './InvoiceHeader';
import InvoiceFooter from './InvoiceFooter';

import Currency from '@/components/format/Currency';

export default {
    components: { InvoiceHeader, InvoiceFooter, Currency },
    props: {
        reportData: Object,
    },
    data() {
        return {
            model: null,
            pages: null,
        };
    },
    methods: {
        chunkArrayInGroups(arr, size) {
            var myArray = [];
            for (var i = 0; i < arr.length; i += size) {
                myArray.push(arr.slice(i, i + size));
            }
            return myArray;
        },
        initialiseFields() {
            const lines = this.reportData.body.lines;
            const lotsOfLines = [
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
                ...lines,
            ];
            const linesPerPage = 12;
            const useLines = this.chunkArrayInGroups(lotsOfLines, linesPerPage);
            this.pages = useLines.map(onePageLines => ({ lines: onePageLines }));
        },
    },
    mounted() {
        this.initialiseFields();
    },
};
</script>
<style scoped>
.report-body {
    background-color: hotpink;
    border: 1px solid #000;
}
.no-overflow {
    overflow: hidden;
}
</style>
