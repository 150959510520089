<template>
    <v-container style="background-color: #00acc1" class="ma-0 pa-0">
        <v-row column>
            <v-col style="background-color: greenyellow;" class="col-4">
                <h3>{{ companyName }}</h3>
                <div>{{ invoiceAddress.line1 }}</div>
                <div>{{ invoiceAddress.line2 }}</div>
                <div>{{ invoiceAddress.line3 }}</div>
                <div>{{ invoiceAddress.line4 }}</div>
            </v-col>
            <v-col style="background-color: greenyellow;" class="col-6">
                <div>{{ invoiceHeader.line1 }}</div>
                <div>{{ invoiceHeader.line2 }}</div>
                <div>{{ invoiceHeader.line3 }}</div>
                <div>{{ invoiceHeader.line4 }}</div>
                <div>{{ invoiceHeader.line5 }}</div>
                <div>{{ invoiceHeader.line6 }}</div>
            </v-col>
            <v-spacer style="background-color: orangered;" />
            <v-col align-center class="d-flex col-2" style="background-color: yellow;">
                <report-title
                    :text="title"
                    :subText1="documentReference"
                    :subText2="`Page ${pageNumber} / ${totalPages}`"
                    style="background-color: #00bfa5;"
                    class="ml-auto"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col-4 address-block" style="background-color: orangered;">
                <div>{{ address.addressLine1 }}</div>
                <div>{{ address.addressLine2 }}</div>
                <div>{{ address.addressLine3 }}</div>
                <div>{{ address.addressLine4 }}</div>
            </v-col>
            <v-col class="col-4 address-block" style="background-color: mediumpurple">
                <div>Date: {{ documentDate }}</div>
                <div v-if="dueDate">Due: {{ dueDate }}</div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ReportTitle from '../common/Title';

export default {
    components: { ReportTitle },
    props: {
        header: Object,
        pageNumber: Number,
        totalPages: Number,
    },
    data() {
        return {
            companyName: null,
            invoiceAddress: null,
            invoiceHeader: null,
            documentDate: null,
            dueDate: null,
            documentReference: null,
            title: null,
            address: null,
        };
    },
    methods: {
        initialiseFields() {
            // const { header, footer, body } = this.reportData;
            // if (!header || !footer || !body) throw new Error(`Invoice bad format`);
            //
            const { companyDetails } = this.header;
            if (!companyDetails) throw new Error('no company details');

            this.companyName = companyDetails.companyName;
            this.invoiceAddress = companyDetails.invoiceAddress || {};
            this.invoiceHeader = companyDetails.invoiceHeader || {};

            this.documentDate = this.header.documentDate;
            this.dueDate = this.header.dueDate;
            this.documentReference = this.header.documentReference;
            this.title = this.header.title;
            this.address = this.header.address || {};
        },
    },
    created() {
        this.initialiseFields();
    },
};
</script>
<style scoped>
.address-block {
    margin: 5mm 0cm 5mm 3cm;
}
</style>
