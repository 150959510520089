<template>
    <fragment>
        <div v-for="(item, itemCount) in batchJobResults" :key="`item${itemCount}`">

            <component
                v-if="item.reportComponentName"
                :is="item.reportComponentName"
                :reportData="item.data"
                :onError="saveError"
            />
        </div>
    </fragment>
</template>
<script>
import api from '@/api';

import AlexsReport from '@/views/reports/format/AlexsReport';
import Invoice from '@/views/reports/format/invoice/Invoice';
import Remittance from '@/views/reports/format/remittance/Remittance';

export default {
    components: { AlexsReport, Invoice, Remittance },
    data() {
        return {
            model: null,
            batchJobId: this.$route.params.id,
            batchJobResults: null,
        };
    },
    methods: {
        async loadReport() {
            const result = await api.get(this.$store, `report/batchjob/${this.batchJobId}`);
            this.batchJobResults = result.data.batchJobResults.map(oneBatchJobResult => ({
                ...oneBatchJobResult,
                reportComponentName: this.useComponent(oneBatchJobResult),
            }));
        },
        useComponent(item) {
            switch (item.request.reportName) {
                case 'alexs report':
                    return 'alexs-report';
                case 'invoice':
                    return 'invoice';
                case 'remittance':
                    return 'remittance';
            }
            return null;
        },
        saveError(ev) {
            // write error back to this.batchJobId
        },
    },
    async mounted() {
        await this.loadReport();
    },
};
</script>
<style></style>
