<template>
    <v-container v-if="header" style="background-color: #00acc1" class="ma-0 pa-0">
        <v-row column>
            <v-col style="background-color: greenyellow" class="col-4">
                <fragment v-if="header.companyDetails">
                    <h3>{{ header.companyDetails.companyName }}</h3>
                    <fragment v-if="header.companyDetails.invoiceAddress">
                        <div>{{ header.companyDetails.invoiceAddress.line1 }}</div>
                        <div>{{ header.companyDetails.invoiceAddress.line2 }}</div>
                        <div>{{ header.companyDetails.invoiceAddress.line3 }}</div>
                        <div>{{ header.companyDetails.invoiceAddress.line4 }}</div>
                    </fragment>
                </fragment>
            </v-col>
            <v-col style="background-color: greenyellow" class="col-6">
                <fragment v-if="header.companyDetails && header.companyDetails.invoiceHeader">
                    <div>{{ header.companyDetails.invoiceHeader.line1 }}</div>
                    <div>{{ header.companyDetails.invoiceHeader.line2 }}</div>
                    <div>{{ header.companyDetails.invoiceHeader.line3 }}</div>
                    <div>{{ header.companyDetails.invoiceHeader.line4 }}</div>
                    <div>{{ header.companyDetails.invoiceHeader.line5 }}</div>
                    <div>{{ header.companyDetails.invoiceHeader.line6 }}</div>
                </fragment>
            </v-col>
            <v-spacer style="background-color: orangered" />
            <v-col align-center class="d-flex col-2" style="background-color: yellow">
                <report-title
                    :text="header.title"
                    :subText1="header.documentReference"
                    :subText2="`Page ${pageNumber} / ${totalPages}`"
                    style="background-color: #00bfa5"
                    class="ml-auto"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col-4 address-block" style="background-color: orangered">
                <div>{{ header.address1 }}</div>
                <div>{{ header.address2 }}</div>
                <div>{{ header.address3 }}</div>
                <div>{{ header.address4 }}</div>
            </v-col>
            <v-col
                :v-if="paymentDetails"
                class="col-4 address-block"
                style="background-color: mediumpurple"
            >
                <div>Run Date: {{ convertDate(header.paymentDetails.paymentRunDate) }}</div>
                <div>
                    Settlement Date: {{ convertDate(header.paymentDetails.settlementDate) }}
                    <fragment
                        v-if="
                            header.paymentDetails.paymentRunDate !==
                            header.paymentDetails.settlementDate
                        "
                    >
                        ***
                    </fragment>
                </div>
                <div>
                    Total Paid:
                    <currency :show-zero="true" :value="header.paymentDetails.paymentTotal" />
                </div>
                <div>Payment Reference: {{ header.paymentDetails.paymentReference }}</div>
            </v-col>
        </v-row>
    </v-container>
    <div v-else>NO HEADER</div>
</template>
<script>
import ReportTitle from '../common/Title';
import Currency from '@/components/format/Currency';
import dateUtil from '@/../common/utils/date';

export default {
    components: { ReportTitle, Currency },
    props: {
        header: Object,
        pageNumber: Number,
        totalPages: Number,
        paymentDetails: null,
    },
    methods: {
        convertDate(date) {
            console.log({ date });
            return dateUtil.ymdToDmy(date);
        },
    },
};
</script>
<style scoped>
.address-block {
    margin: 5mm 0cm 5mm 3cm;
}
pre {
    display: inline;
    margin: 0;
}
</style>
