<template>
    <v-container style="background-color: #00acc1" class="ma-0 pa-0">
        <v-row column>
            <v-col style="background-color: greenyellow" class="col-4"> </v-col>
            <v-col style="background-color: greenyellow" class="col-6"> </v-col>
            <v-spacer style="background-color: orangered" />
            <v-col align-center class="col-2 text-right" style="background-color: yellow">
                <div style="background-color: greenyellow">
                    Generated: {{ remittanceTime }}
                    <div v-if="pageNumber === totalPages" class="mr-1"></div>
                    <div v-else>Continued on page {{ pageNumber + 1 }}</div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import dateUtil from '@/../common/utils/date';

export default {
    props: {
        footer: Object,
        pageNumber: Number,
        totalPages: Number,
    },

    data() {
        return {
            remittanceTime: null,
        };
    },
    methods: {
        initialiseFields() {
            this.remittanceTime = `${dateUtil.dateToTime(this.footer.generatedTime)} ${dateUtil.dateToString(
                this.footer.generatedTime,
            )}`;
        },
    },
    created() {
        this.initialiseFields();
    },
};
</script>
<style scoped>
.address-block {
    margin: 1cm 0cm 1cm 3cm;
}
</style>
