<template>
    <fragment>
        <report-page v-if="reportData.customer" id="reportLoaded" landscape>
            <template slot="header">
                <h1>Alexs Report</h1>
                Customer!! <br />
                Customer!! <br />
                Customer!! <br />
                Customer!! <br />
            </template>
            <template slot="footer">
                Footer <br />
                Footer <br />
                Footer <br />
                Footer <br />
            </template>
            {{ reportData.customer.customerCode }}:
            {{ reportData.customer.customerName }}
            <div v-for="lineCount in 12" :key="`lineA${lineCount}`">Line {{ lineCount }}</div>
        </report-page>
    </fragment>
</template>
<script>
export default {
    props: {
        reportData: Object,
    },
    data() {
        return {
            model: null,
        };
    },
};
</script>
