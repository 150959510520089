<template>
    <!--    <report-error-->
    <!--        v-if="errorMessage"-->
    <!--        :error-message="errorMessage"-->
    <!--        :error-description="errorDescription"-->
    <!--        :error-debug="errorDebug"-->
    <!--        key="error"-->
    <!--    />-->
    <!--        v-else-if="this.pages"-->
    <div v-if="this.pages" id="reportLoaded">
        <report-page
            v-for="(page, pageCounter) in pages"
            landscape
            :key="`invoicePage${pageCounter}`"
        >
            <remittance-header
                :header="reportData.header"
                :pageNumber="pageCounter + 1"
                :totalPages="pages.length"
                slot="header"
            />
            <remittance-footer
                :footer="reportData.footer"
                :pageNumber="pageCounter + 1"
                :totalPages="pages.length"
                slot="footer"
            />
            <!--            <pre>{{ JSON.stringify(page, null, 2) }}</pre>-->
            <!--                <div>-->
            <v-container class="report-body pa-0 ma-0">
                <v-row
                    class="ma-0 pa-0 col-12 report-body"
                    style="background-color: #00bfa5; font-weight: bold"
                >
                    <v-col class="col-1 ma-0 pa-1 text-left">Type</v-col>
                    <v-col class="col-2 ma-0 pa-1 text-center">Date</v-col>
                    <v-col class="col-2 ma-0 pa-1">Reference</v-col>
                    <v-col class="col-2 ma-0 pa-1 text-right">Document Amount</v-col>
                    <v-col class="col-2 ma-0 py-1 text-right">This Payment</v-col>
                    <v-col class="col-3 ma-0 py-1 text-right">Remaining Balance</v-col>
                </v-row>
                <v-row
                    v-for="(line, lineCount) in page.lines"
                    :key="`page${pageCounter}line${lineCount}`"
                    class="ma-0 pa-0"
                    style="font-weight: 350"
                >
                    <v-col class="col-1 ma-0 pa-1 text-left">{{ line.documentType }}</v-col>
                    <v-col class="col-2 ma-0 pa-1 text-center">{{ line.documentDate }}</v-col>

                    <v-col class="col-2 ma-0 pa-1">{{ line.documentReference }}</v-col>
                    <v-col class="col-2 ma-0 pa-1 text-right">
                        <currency :value="line.documentTotal" />
                    </v-col>
                    <v-col class="col-2 ma-0 py-1 text-right">
                        <currency :value="line.paymentAmount" />
                    </v-col>
                    <v-col class="col-3 ma-0 pa-1 text-right" style="background-color: #00c853">
                        <currency :value="line.documentBalance" show-zero />
                    </v-col>
                </v-row>
            </v-container>
            <!--                </div>-->
        </report-page>
    </div>
</template>
<script>
import RemittanceHeader from './RemittanceHeader';
import RemittanceFooter from './RemittanceFooter';

// import ReportError from '@/components/common/ReportError';

import Currency from '@/components/format/Currency';
// components: { RemittanceHeader, RemittanceFooter, ReportError, Currency },

export default {
    components: { RemittanceHeader, RemittanceFooter, Currency },
    props: {
        reportData: Object,
    },
    data() {
        return {
            model: null,
            pages: null,
            errorMessage: '',
            errorDescription: '',
            errorDebug: null,
        };
    },
    methods: {
        chunkArrayInGroups(arr, size) {
            const myArray = [];
            for (let i = 0; i < arr.length; i += size) {
                myArray.push(arr.slice(i, i + size));
            }
            return myArray;
        },
        initialiseFields() {
            try {
                const lines = this.reportData.body.lines;
                const linesPerPage = 11;
                const useLines = this.chunkArrayInGroups(lines, linesPerPage);
                this.pages = useLines.map((onePageLines) => ({ lines: onePageLines }));
            } catch (e) {
                console.log(e);
            }
        },
    },
    created() {
        setTimeout(() => {
            this.errorMessage = 'Remittance Error';
            this.errorDescription = 'time out error';
            this.errorDebug = this.reportData;
        }, 10000);
        this.initialiseFields();
    },
};
</script>
<style scoped>
.report-body {
    background-color: hotpink;
    border: 1px solid #000;
}
.no-overflow {
    overflow: hidden;
}
</style>
